import lodashCamelCase from 'lodash/camelCase';

export default function (store, { $http }) {
  return {
    status: {
      0: {
        label: 'Idea',
        color: '#3498db',
      },
      1: {
        label: 'In Development',
        color: '#f7ba64',
      },
      2: {
        label: 'Ready To Order',
        color: '#5ad579',
      },
      3: {
        label: 'Archive',
        color: '#959595',
      },
    },
    productArticles: {},
    get statusKeys() {
      return Object.keys(this.status);
    },
    get statusNames() {
      return Object.values(this.status).map((item) => lodashCamelCase(item.label));
    },
    updateProductArticle(article) {
      // TODO: FIXME - quick and dirty have - this should not be done this way, but through options on parent function
      if (!this.productArticles?.[article.product.id]) return;
      this.productArticles?.[article.product.id].splice(
        this.productArticles[article.product.id].findIndex((a) => a.id === article.id),
        1,
        article
      );
    },

    async addArticleToProduct(productId, { color } = {}) {
      const data = { productId };
      if (color) {
        data.color = color;
      }
      const { data: article } = await $http.post('/api/articles', data);
      this.productArticles[productId].push(article);
    },

    async getArticle(id) {
      const { data: article } = await $http.get(`/api/articles/${id}`);
      return article;
    },

    async deleteArticle(id) {
      return await $http.delete(`/api/articles/${id}`);
    },

    async cloneArticles(ids) {
      const { data: articles } = await $http.post(`/api/articles/clone`, ids);
      return articles;
    },

    async updateDescription(id, description) {
      const { data: article } = await $http.put(`/api/articles/${id}/description`, { description });

      return { article };
    },

    async updateProductTypeCategory({ articleId, productTypeId, productCategoryId }) {
      const {
        data: { article, productUpdated },
      } = await $http.put(`/api/articles/${articleId}/producttypecategory`, {
        productTypeId,
        productCategoryId,
      });

      this.updateProductArticle(article);

      return { article };
    },

    async updateLast() {
      const articleID = 1;
      const lastID = 2;

      const { data: article } = await $http.put(`/api/articles/${articleID}/last`, { lastID });
    },

    async updateMeasurementList() {
      const articleID = 1;
      const measurementListID = 2;

      const { data: article } = await $http.put(`/api/articles/${articleID}/measurementlist`, { measurementListID });
    },

    async updateArticleNumber(articleId, articleNumber) {
      const { data: article } = await $http.put(`/api/articles/${articleId}/article-number`, { articleNumber });

      this.updateProductArticle(article);
    },

    async updateTags(articleId, tagIds, { reloadProduct } = {}) {
      const {
        data: { article, productUpdated },
      } = await $http.put(`/api/articles/${articleId}/tags`, tagIds);

      this.updateProductArticle(article);

      return { article, productUpdated };
    },

    async updateSeasons(articleId, seasonIds, { reloadProduct } = {}) {
      const {
        data: { article, productUpdated },
      } = await $http.put(`/api/articles/${articleId}/seasons`, seasonIds);

      this.updateProductArticle(article);

      return { article, productUpdated };
    },

    async updateSuppliers(articleId, supplierIds, { reloadProduct } = {}) {
      const {
        data: { article, productUpdated },
      } = await $http.put(`/api/articles/${articleId}/suppliers`, supplierIds);

      this.updateProductArticle(article);

      return { article, productUpdated };
    },

    async updateOrder(articleId, orderId, quantity) {
      const { data: article } = await $http.put(`/api/articles/${articleId}/orders/${orderId}`, {
        quantity,
      });
    },

    async updatePlans(articleId, plans, { reloadProduct } = {}) {
      const simplifiedPlans = plans.map((plan) => {
        const simplifiedPlan = {
          id: plan.id,
          quantity: plan.quantity,
        };

        // New plan
        if (!plan.id) {
          Object.assign(simplifiedPlan, {
            inboundDate: plan.inboundDate,
            typeId: plan.type?.id,
          });
        }

        return simplifiedPlan;
      });

      const {
        data: { article, ordersUpdated },
      } = await $http.put(`/api/articles/${articleId}/plans`, simplifiedPlans);

      this.updateProductArticle(article);

      return { article, ordersUpdated };
    },

    async updatePrices(articleId, prices, { reloadProduct } = {}) {
      const {
        data: { article, productUpdated },
      } = await $http.put(`/api/articles/${articleId}/prices`, prices);

      this.updateProductArticle(article);

      return { article, productUpdated };
    },

    async updateComment(articleId, comment) {
      const { data: article } = await $http.put(`/api/articles/${articleId}/comment`, { comment });

      this.updateProductArticle(article);

      return { article };
    },

    async updateStatus(articleId, status, { reloadProduct } = {}) {
      const {
        data: { article, productUpdated, missingProperties },
      } = await $http.put(`/api/articles/${articleId}/status`, { status });

      // Missing required properties
      if (missingProperties.required.length) {
        return { missingProperties };
      }

      this.updateProductArticle(article);

      return { article, productUpdated, missingProperties };
    },

    async updateColor(articleId, color, { reloadProduct } = {}) {
      if (color?.colorPaletteColor) {
        color = { colorPaletteColorId: color.colorPaletteColor.id, groupId: color.groupId };
      }

      const { data: article } = await $http.put(`/api/articles/${articleId}/color`, color, {
        headers: { 'Content-Type': 'application/json' },
      }); // Force JSON to be able to handle null/emptyish data

      this.updateProductArticle(article);

      return { article };
    },
    async updateWeight(articleId, weight) {
      const { data: article } = await $http.put(`/api/articles/${articleId}/weight`, weight);

      this.updateProductArticle(article);

      return { article };
    },
    async updateTariffCodes(articleId, tariffCodes) {
      const { data: article } = await $http.put(`/api/articles/${articleId}/tariffCodes`, tariffCodes);

      this.updateProductArticle(article);

      return { article };
    },
    async updatePrincipalType(articleId, subTypeId, slotId) {
      const { data: article } = await $http.put(`/api/articles/${articleId}/principal-type`, {
        subTypeId: subTypeId,
        slotId,
      });

      this.updateProductArticle(article);

      return { article };
    },
    // TODO: REVIEW if we should turn these 2 set methods into POST instead of PUT (since they're generating stuff, not updating)
    async setEanCodes(articleId) {
      await $http.put(`/api/articles/${articleId}/eanCodes`);
    },
    async setEanCode(articleId, size) {
      const { data: eanCode } = await $http.put(`/api/articles/${articleId}/eanCodes/${size}`);

      return { eanCode };
    },
    // TODO: Move into SKU store? See also backend
    async updateEanCode(skuId, eanCode) {
      const { data: article } = await $http.put(`/api/articles/skus/${skuId}/eanCode`, { eanCode });

      return { article };
    },
    async updateCustomField(articleId, customFieldId, data) {
      const stringifiedData = JSON.stringify(data); // Needed to preserve the proper type

      const { data: article } = await $http.put(
        `/api/articles/${articleId}/customFields/${customFieldId}`,
        stringifiedData,
        {
          headers: {
            'Content-Type': 'application/json', // Force JSON, even for primitive values, since the API expects/needs it that way
          },
        }
      );

      this.updateProductArticle(article);

      return { article };
    },
    async uploadAndImportArticlesFromExcel(brandId, inFile) {
      await $http.post(`/api/articles/brand/${brandId}/import-excel-file`, inFile, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    created() {
      // To force Vue'ification of module, since then we can use this.$set (and if needed in the future this.$ga)
    },

    // Articles list management
    async sortArticlesList(productId) {},
    async groupArticlesList(productId) {},

    async editCertainArticlesInList(aticlesIDs = [], productId) {},
    async copyCertainArticlesInList(aticlesIDs = [], productId) {},
    async archiveCertainArticlesInList(aticlesIDs = [], productId) {},
    async deleteCertainArticlesInList(aticlesIDs = [], productId) {},
  };
}
